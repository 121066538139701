import { useLocation } from 'react-router-dom';
export var useMenuActive = function () {
    var location = useLocation();
    var path = location.pathname;
    var match = path.match(/^\/([^/]+)/);
    if (match) {
        return match[1];
    }
    return 'home';
};
