import { jsx as _jsx } from "react/jsx-runtime";
import { OptionsTheme, selectorThemeType, switchTheme } from '@entities/theme';
import { useAppDispatch, useAppSelector } from '@shared/hooks';
import { Select } from '@shared/ui';
import { useTranslation } from 'react-i18next';
export var ThemeSwitcher = function () {
    var dispatch = useAppDispatch();
    var t = useTranslation().t;
    var theme = useAppSelector(selectorThemeType);
    var handlerSwitchTheme = function (value) {
        dispatch(switchTheme(value));
    };
    return _jsx(Select, { value: theme, onSelect: handlerSwitchTheme, options: OptionsTheme(t) });
};
