import { breakpoints } from '@shared/constants';
// TODO - добавить размеры (Header)
var borderRadius = {
    roundedNon: '0px',
    roundedXS: '4px',
    roundedS: '8px',
    roundedM: '12px',
    roundedL: '16px',
    roundedXL: '20px',
    roundedFull: '99%',
};
var shadow = {
    base: '0px 0px 12px 0px rgba(14, 53, 42, .1)',
    active: '0px 0px 20px 0px rgba(14, 53, 42, .2)',
};
var Breakpoints = {
    maxMobile: "".concat(breakpoints.maxMobile, "px"),
    maxTablets: "".concat(breakpoints.maxTablets, "px"),
    maxLaptops: "".concat(breakpoints.maxLaptops, "px"),
    maxDesktops: "".concat(breakpoints.maxDesktops, "px"),
};
var size = {
    headerL: '104px',
    headerS: '52px',
};
var lightCore = {
    colorPrimary: '#1C1C1C',
    colorSecondary: '#A18A68',
    colorTextPrimary: '#1C1C1C',
    colorTextSecondary: '#707070',
    colorDisabled: '#EFEFEF',
    colorErrorPrimary: '#C51400',
    colorAccentPrimary: '#D8D8D8',
    colorSurface: '#EFEFEF',
    colorSystem: 'red',
    colorBackground: '#FFFFFF',
    colorDivider: 'red',
    boxShadow: '#cecece',
};
var darkCore = {
    colorPrimary: '#ffffff',
    colorSecondary: '#B4A186',
    colorTextPrimary: '#ffffff',
    colorTextSecondary: '#9A9A9A',
    colorDisabled: '#EFEFEF',
    colorErrorPrimary: '#C51400',
    colorAccentPrimary: '#3D3D3D',
    colorSurface: '#272727',
    colorSystem: 'red',
    colorBackground: '#000000',
    colorDivider: 'red',
    boxShadow: '#373535',
};
export var lightTheme = {
    core: lightCore,
    borderRadius: borderRadius,
    shadow: shadow,
    size: size,
    breakpoints: Breakpoints,
};
export var DarkTheme = {
    core: darkCore,
    borderRadius: borderRadius,
    shadow: shadow,
    size: size,
    breakpoints: Breakpoints,
};
