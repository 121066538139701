var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var Logout = function (_a) {
    var color = _a.color, props = __rest(_a, ["color"]);
    return (_jsx("svg", __assign({ width: "22", height: "22", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512" }, props, { children: _jsx("path", { fill: color, d: "M479.2 254.2c.5 .5 .8 1.1 .8 1.8s-.3 1.4-.8 1.8L347.3 382.2c-1.2 1.2-2.9 1.8-4.6 1.8c-3.7 0-6.7-3-6.7-6.7l0-57.3c0-8.8-7.2-16-16-16l-120 0c-4.4 0-8-3.6-8-8l0-80c0-4.4 3.6-8 8-8l120 0c8.8 0 16-7.2 16-16l0-57.3c0-3.7 3-6.7 6.7-6.7c1.7 0 3.3 .7 4.6 1.8L479.2 254.2zM512 256c0-9.5-3.9-18.6-10.8-25.1L369.2 106.5C362 99.8 352.5 96 342.7 96c-21.4 0-38.7 17.3-38.7 38.7l0 41.3-104 0c-22.1 0-40 17.9-40 40l0 80c0 22.1 17.9 40 40 40l104 0 0 41.3c0 21.4 17.3 38.7 38.7 38.7c9.9 0 19.4-3.8 26.5-10.5L501.2 281.1c6.9-6.5 10.8-15.6 10.8-25.1zM176 64c8.8 0 16-7.2 16-16s-7.2-16-16-16L80 32C35.8 32 0 67.8 0 112L0 400c0 44.2 35.8 80 80 80l96 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-96 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48l96 0z" }) })));
};
