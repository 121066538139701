import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NotFoundNetworkPage from '@pages/NotFoundNetworkPage';
import { useNetwork } from '@shared/hooks';
import { Header } from '@widgets/Header';
import { Outlet } from 'react-router-dom';
import { SDefaultScreen, SLayout, SWrapper } from './defaultScreen.styles';
var DefaultScreen = function () {
    var isOnline = useNetwork();
    if (!isOnline)
        return _jsx(NotFoundNetworkPage, {});
    return (_jsxs(SDefaultScreen, { children: [_jsx(Header, {}), _jsx(SLayout, { children: _jsx(SWrapper, { children: _jsx(Outlet, {}) }) })] }));
};
export default DefaultScreen;
