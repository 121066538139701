export var itemsMenu = [
    {
        key: 'shop',
        label: 'Shop',
    },
    {
        key: 'blog',
        label: 'Blog',
    },
    {
        key: '3',
        label: 'Our Story',
    },
];
export var itemsMobileMenu = [
    {
        key: 'home',
        label: 'Home',
    },
    {
        key: 'shop',
        label: 'Shop',
    },
    {
        key: 'about',
        label: 'About',
    },
    {
        key: 'blog',
        label: 'Blog',
    },
    {
        key: '5',
        label: 'Help',
    },
    {
        key: '6',
        label: 'Contact',
    },
];
