var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer } from 'antd';
import styled from 'styled-components';
export var SDrawer = styled(Drawer).attrs({
    placement: 'right',
    width: '100%',
    forceRender: true,
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 16px;\n\n  & .ant-drawer-header {\n    border-bottom: 0 !important;\n    display: none;\n  }\n\n  & .ant-drawer-body {\n    padding: 0;\n  }\n\n  & .ant-drawer-header-title {\n    justify-content: right;\n  }\n\n  & .ant-drawer-close {\n    margin-inline-end: 0;\n  }\n"], ["\n  padding: 0 16px;\n\n  & .ant-drawer-header {\n    border-bottom: 0 !important;\n    display: none;\n  }\n\n  & .ant-drawer-body {\n    padding: 0;\n  }\n\n  & .ant-drawer-header-title {\n    justify-content: right;\n  }\n\n  & .ant-drawer-close {\n    margin-inline-end: 0;\n  }\n"])));
export var SBurgerMenu = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n"])));
export var SHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  margin: 0 auto;\n  width: 100%;\n  height: ", ";\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  margin: 0 auto;\n  width: 100%;\n  height: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.size.headerS;
});
export var SBurgerMenuCloseIcon = styled(FontAwesomeIcon).attrs(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.core.colorPrimary,
        size: '2xl',
    });
})(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
