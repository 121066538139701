import { useEffect, useState } from 'react';
export var useNetwork = function () {
    var _a = useState(true), isOnline = _a[0], setIsOnline = _a[1];
    var handleStatusChange = function () { return setIsOnline(navigator.onLine); };
    useEffect(function () {
        window.addEventListener('offline', handleStatusChange);
        window.addEventListener('online', handleStatusChange);
        return function () {
            window.removeEventListener('offline', handleStatusChange);
            window.removeEventListener('online', handleStatusChange);
        };
    }, [isOnline]);
    return isOnline;
};
