import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    themeType: 'light',
};
var ThemeSlice = createSlice({
    name: 'theme',
    initialState: initialState,
    reducers: {
        switchTheme: function (state, _a) {
            var payload = _a.payload;
            state.themeType = payload;
        },
    },
});
export var switchTheme = ThemeSlice.actions.switchTheme;
export default ThemeSlice.reducer;
