var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Layout } from 'antd';
import styled from 'styled-components';
export var SDefaultScreen = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 100vh;\n  width: 100%;\n  background-color: ", ";\n"], ["\n  min-height: 100vh;\n  width: 100%;\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.core.colorBackground;
});
export var SLayout = styled(Layout)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  height: max-content;\n  padding: 16px;\n  background-color: inherit;\n  max-width: ", ";\n  margin: 0 auto;\n\n  @media (max-width: ", ") {\n    padding: 0 10px;\n  }\n"], ["\n  display: grid;\n  height: max-content;\n  padding: 16px;\n  background-color: inherit;\n  max-width: ", ";\n  margin: 0 auto;\n\n  @media (max-width: ", ") {\n    padding: 0 10px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.maxDesktops;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.maxTablets;
});
export var SWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  height: 100%;\n  overflow: hidden;\n  max-width: ", "px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  height: 100%;\n  overflow: hidden;\n  max-width: ", "px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.maxDesktops;
});
var templateObject_1, templateObject_2, templateObject_3;
