import { theme } from '@shared/strings';
export var OptionsTheme = function (t) { return [
    {
        title: t(theme.dark),
        value: 'dark',
        label: t(theme.dark),
    },
    {
        title: t(theme.light),
        value: 'light',
        label: t(theme.light),
    },
]; };
