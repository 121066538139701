import { jsx as _jsx } from "react/jsx-runtime";
import NotFoundPage from '@pages/NotFoundPage';
import { ERoutes } from '@shared/config';
import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import DefaultScreen from '../layout/DefaultScreen';
var HomePageLazy = lazy(function () { return import('../../pages/HomePage'); });
var AuthPageLazy = lazy(function () { return import('../../pages/AuthPage'); });
var ShopPageLazy = lazy(function () { return import('@pages/ProductPage/ui/ProductPage'); });
export var publicRoutes = createBrowserRouter([
    {
        path: ERoutes.HOME_PAGE,
        element: _jsx(DefaultScreen, {}),
        errorElement: _jsx(NotFoundPage, {}),
        children: [{ path: ERoutes.HOME_PAGE, element: _jsx(AuthPageLazy, {}), children: [] }],
    },
]);
export var privateRoutes = createBrowserRouter([
    {
        path: ERoutes.HOME_PAGE,
        element: _jsx(DefaultScreen, {}),
        errorElement: _jsx(NotFoundPage, {}),
        children: [
            {
                path: ERoutes.HOME_PAGE,
                element: _jsx(HomePageLazy, {}),
            },
            {
                path: ERoutes.SHOP_PAGE,
                element: _jsx(ShopPageLazy, {}),
            },
            {
                path: '/shop/:id',
                element: _jsx("div", { children: "shop id" }),
            },
        ],
    },
]);
