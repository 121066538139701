import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE, } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './rootReducer';
var persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['theme'],
};
var persistedReducer = persistReducer(persistConfig, rootReducer);
export var setupStore = function () {
    return configureStore({
        reducer: persistedReducer,
        middleware: function (getDefaultMiddleware) {
            return getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [
                        FLUSH,
                        REHYDRATE,
                        PAUSE,
                        PERSIST,
                        PURGE,
                        REGISTER,
                        'acts/getPrintForm/fulfilled', // Игнорирование Blob action
                    ],
                },
            });
        },
    });
};
export var store = setupStore();
export var persistor = persistStore(store);
