var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { BurgerMenu, Cart, Logo } from '@shared/assets';
import styled from 'styled-components';
export var SHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  margin: 0 auto;\n  padding: 0 16px;\n  width: 100%;\n  max-width: ", ";\n  height: ", ";\n  border-bottom: 1px solid ", ";\n\n  @media (max-width: ", ") {\n    height: ", ";\n    border-bottom: 0 solid ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n  margin: 0 auto;\n  padding: 0 16px;\n  width: 100%;\n  max-width: ", ";\n  height: ", ";\n  border-bottom: 1px solid ", ";\n\n  @media (max-width: ", ") {\n    height: ", ";\n    border-bottom: 0 solid ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.maxDesktops;
}, function (_a) {
    var theme = _a.theme;
    return theme.size.headerL;
}, function (_a) {
    var theme = _a.theme;
    return theme.core.colorAccentPrimary;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.maxTablets;
}, function (_a) {
    var theme = _a.theme;
    return theme.size.headerS;
}, function (_a) {
    var theme = _a.theme;
    return theme.core.colorAccentPrimary;
});
export var SLogo = styled(Logo).attrs(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.core.colorPrimary,
    });
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 70px;\n\n  @media (max-width: ", ") {\n    height: max-content;\n  }\n"], ["\n  height: 70px;\n\n  @media (max-width: ", ") {\n    height: max-content;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.maxTablets;
});
export var SBurgerMenuIcon = styled(BurgerMenu).attrs(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.core.colorPrimary,
    });
})(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var CartIcon = styled(Cart).attrs(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.core.colorPrimary,
    });
})(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var SStrippedMenu = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 20px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 20px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
