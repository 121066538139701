var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Cart, Logout, Search, User } from '@shared/assets';
import { Tabs } from '@shared/ui';
import { Menu as MenuAntd } from 'antd';
import styled from 'styled-components';
export var SFutures = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 30px;\n\n  @media (max-width: ", ") {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: start;\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 30px;\n\n  @media (max-width: ", ") {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: start;\n    width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.maxTablets;
});
export var SMenu = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 50px;\n\n  @media (max-width: ", ") {\n    flex-direction: column;\n    justify-content: space-between;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 50px;\n\n  @media (max-width: ", ") {\n    flex-direction: column;\n    justify-content: space-between;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.maxTablets;
});
export var SDivider = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  width: 1px;\n  height: 20px;\n\n  @media (max-width: ", ") {\n    background-color: ", ";\n    width: 100%;\n    height: 1px;\n  }\n"], ["\n  background-color: ", ";\n  width: 1px;\n  height: 20px;\n\n  @media (max-width: ", ") {\n    background-color: ", ";\n    width: 100%;\n    height: 1px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.core.colorTextSecondary;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.maxTablets;
}, function (_a) {
    var theme = _a.theme;
    return theme.core.colorAccentPrimary;
});
export var STabs = styled(Tabs)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  &.ant-tabs-top > .ant-tabs-nav {\n    height: 70px;\n  }\n"], ["\n  &.ant-tabs-top > .ant-tabs-nav {\n    height: 70px;\n  }\n"])));
export var SMenuAntd = styled(MenuAntd)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  &.ant-menu {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    border-inline-end: 0 solid rgba(5, 5, 5, 0.06) !important;\n    font-size: 30px;\n\n    @media (max-width: ", ") {\n      font-size: 25px;\n    }\n  }\n\n  & .ant-menu-item {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    @media (max-width: ", ") {\n      justify-content: start;\n    }\n  }\n\n  & li {\n    @media (max-width: ", ") {\n      padding-inline: 0px !important;\n    }\n  }\n"], ["\n  &.ant-menu {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    border-inline-end: 0 solid rgba(5, 5, 5, 0.06) !important;\n    font-size: 30px;\n\n    @media (max-width: ", ") {\n      font-size: 25px;\n    }\n  }\n\n  & .ant-menu-item {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    @media (max-width: ", ") {\n      justify-content: start;\n    }\n  }\n\n  & li {\n    @media (max-width: ", ") {\n      padding-inline: 0px !important;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.maxTablets;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.maxTablets;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.maxTablets;
});
export var SearchIcon = styled(Search).attrs(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.core.colorPrimary,
    });
})(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
export var CartIcon = styled(Cart).attrs(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.core.colorPrimary,
    });
})(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
export var UserIcon = styled(User).attrs(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.core.colorPrimary,
    });
})(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
export var LogoutIcon = styled(Logout).attrs(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.core.colorPrimary,
    });
})(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
