import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu } from '@features/Menu';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { SBurgerMenu, SBurgerMenuCloseIcon, SDrawer, SHeader } from './burgerMenu.styles';
import HeaderMobile from '../HeaderMobile';
var BurgerMenu = function (_a) {
    var onClose = _a.onClose, isOpen = _a.isOpen;
    return (_jsx(SDrawer, { open: isOpen, children: _jsxs(SBurgerMenu, { children: [_jsx(SHeader, { children: _jsx(HeaderMobile, { iconDrawer: _jsx(SBurgerMenuCloseIcon, { icon: faClose, onClick: onClose }) }) }), _jsx(Menu, {})] }) }));
};
export default BurgerMenu;
