var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var SMenuItem = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: start;\n  gap: 10px;\n"], ["\n  display: flex;\n  align-items: start;\n  gap: 10px;\n"])));
export var SDescription = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 25px;\n  color: ", ";\n"], ["\n  font-size: 25px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.core.colorPrimary;
});
var templateObject_1, templateObject_2;
