import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GlobalStyles } from '@app/styles';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import WithAntdConfig from './WithAntdConfig';
import WithPersist from './WithPersist';
import WithQueryClient from './WithQueryClient';
import WithStore from './WithStore';
import WithTheme from './WithTheme';
dayjs.locale(ru);
dayjs.extend(utc);
dayjs.extend(timezone);
var WithProviders = function (_a) {
    var children = _a.children;
    return (_jsx(WithStore, { children: _jsx(WithQueryClient, { children: _jsx(WithPersist, { children: _jsx(WithTheme, { children: _jsxs(WithAntdConfig, { children: [_jsx(GlobalStyles, {}), children] }) }) }) }) }));
};
export default WithProviders;
