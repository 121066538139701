import { jsx as _jsx } from "react/jsx-runtime";
import { selectorThemeType, themePick } from '@entities/theme';
import { useAppSelector } from '@shared/hooks';
import { ThemeProvider } from 'styled-components';
var WithTheme = function (_a) {
    var children = _a.children;
    var theme = useAppSelector(selectorThemeType);
    return _jsx(ThemeProvider, { theme: themePick(theme), children: children });
};
export default WithTheme;
