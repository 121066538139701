import { QueryClient } from '@tanstack/react-query';
export var cacheTime = 60000 * 5;
export var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: cacheTime,
            gcTime: cacheTime,
            retry: 0,
            refetchOnMount: false,
        },
    },
});
