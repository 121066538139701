import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDevice } from '@shared/hooks';
import { useNavigate } from 'react-router-dom';
import { CartIcon, LogoutIcon, SDivider, SearchIcon, SFutures, SMenu, SMenuAntd, STabs, UserIcon, } from './menu.styles';
import MenuItemIcon from './MenuItemIcon/MenuItemIcon';
import { itemsMenu, itemsMobileMenu } from '../lib/itemsMenu';
import { useMenuActive } from '../lib/useMenuActive';
export var Menu = function () {
    var isTablets = useDevice().isTablets;
    var activeKey = useMenuActive();
    var navigate = useNavigate();
    return (_jsxs(SMenu, { children: [isTablets && (_jsx(SMenuAntd, { onClick: function (_a) {
                    var key = _a.key;
                    return navigate("/".concat(key));
                }, defaultSelectedKeys: [activeKey], items: itemsMobileMenu })), !isTablets && (_jsx(STabs, { onTabClick: function (key) { return navigate("/".concat(key)); }, activeKey: activeKey, items: itemsMenu })), _jsx(SDivider, {}), _jsxs(SFutures, { children: [!isTablets && (_jsxs(_Fragment, { children: [_jsx(SearchIcon, {}), _jsx(CartIcon, {}), _jsx(UserIcon, {})] })), isTablets && (_jsxs(_Fragment, { children: [_jsx(MenuItemIcon, { icon: _jsx(UserIcon, {}), description: "My account" }), _jsx(MenuItemIcon, { icon: _jsx(LogoutIcon, {}), description: "Logout" })] }))] })] }));
};
