import { jsx as _jsx } from "react/jsx-runtime";
import { antdTheme } from '@app/styles';
import { selectorThemeType, themePick } from '@entities/theme';
import { useAppSelector } from '@shared/hooks';
import { ConfigProvider } from 'antd';
var WithAntdConfig = function (_a) {
    var children = _a.children;
    var theme = useAppSelector(selectorThemeType);
    return _jsx(ConfigProvider, { theme: antdTheme(themePick(theme)), children: children });
};
export default WithAntdConfig;
