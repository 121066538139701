var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var BurgerMenu = function (_a) {
    var color = _a.color, props = __rest(_a, ["color"]);
    return (_jsxs("svg", __assign({ width: "21", height: "17", viewBox: "0 0 21 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("path", { d: "M1.0545 2.87494L19.7976 2.87494C20.1425 2.87494 20.4222 2.45491 20.422 1.93747C20.4218 1.41995 20.1418 1 19.7969 1L1.05377 1C0.708884 1 0.429179 1.42003 0.42938 1.93747C0.429581 2.45491 0.709664 2.87494 1.0545 2.87494Z", fill: color, stroke: "white", strokeWidth: "0.2" }), _jsx("path", { d: "M19.7972 7.5625L1.05408 7.5625C0.709189 7.5625 0.429484 7.98253 0.429685 8.49997C0.429886 9.0174 0.709969 9.43743 1.05481 9.43743L19.7979 9.43743C20.1428 9.43743 20.4225 9.01741 20.4223 8.49997C20.4221 7.98253 20.1421 7.5625 19.7972 7.5625Z", fill: color, stroke: "white", strokeWidth: "0.2" }), _jsx("path", { d: "M19.797 14.125L7.30175 14.125C6.95687 14.125 6.67716 14.545 6.67735 15.0625C6.67754 15.58 6.95761 15.9999 7.30244 15.9999L19.7977 15.9999C20.1426 15.9999 20.4223 15.5799 20.4221 15.0625C20.4219 14.545 20.1419 14.125 19.797 14.125Z", fill: color, stroke: "white", strokeWidth: "0.2" })] })));
};
