import { breakpoints } from '@shared/constants';
import { useMediaQuery } from 'react-responsive';
export var useDevice = function () {
    var isMobile = useMediaQuery({ query: "(max-width: ".concat(breakpoints.maxMobile, "px)") });
    var isTablets = useMediaQuery({ query: "(max-width: ".concat(breakpoints.maxTablets, "px)") });
    var isLaptops = useMediaQuery({ query: "(max-width: ".concat(breakpoints.maxLaptops, "px)") });
    var isDesktop = useMediaQuery({ query: "(min-width: ".concat(breakpoints.maxDesktops, "px)") });
    return {
        isMobile: isMobile,
        isTablets: isTablets,
        isLaptops: isLaptops,
        isDesktop: isDesktop,
    };
};
