import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu } from '@features/Menu';
import { useDevice } from '@shared/hooks';
import { useState } from 'react';
import BurgerMenu from './BurgerMenu';
import { SBurgerMenuIcon, SHeader, SLogo } from './header.styles';
import HeaderMobile from './HeaderMobile';
var Header = function () {
    var isTablets = useDevice().isTablets;
    var _a = useState(false), isOpenBurgerMenu = _a[0], setIsOpenBurgerMenu = _a[1];
    var handlerOpenMenu = function () {
        setIsOpenBurgerMenu(function (prev) { return !prev; });
    };
    return (_jsxs(SHeader, { children: [isTablets && (_jsxs(_Fragment, { children: [_jsx(BurgerMenu, { isOpen: isOpenBurgerMenu, onClose: handlerOpenMenu }), _jsx(HeaderMobile, { iconDrawer: _jsx(SBurgerMenuIcon, { onClick: handlerOpenMenu }) })] })), !isTablets && (_jsxs(_Fragment, { children: [_jsx(SLogo, {}), _jsx(Menu, {})] }))] }));
};
export default Header;
